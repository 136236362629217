body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  height: 100%;
}

html {
  margin: 0;
  padding: 0;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* REGION LAYOUT */
.appBarStyle {
  position: absolute;
  width: 100%;
}

.appBarLogoSize {
  width: 10rem;
}

.backgroundImage {
  background-image: url("assets/Sfondo_texture.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: repeat-x;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: stretch;
}

/* REGION LOADER*/
.backdrop {
  color: #CCCCCC !important;
  z-index: 1500;
}

.loader {
  color: white !important;
}

/* REGION VISUALIZATION */
.centerVisualization {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.spaceAroundVisualization {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.iframeContainer {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.slideContainer {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.audioSlideContainer {
 align-items: center;
 justify-content: center;
 height: 100vh;
}

.iframeContent {
  flex-grow: 1;
  border: none;
  margin: 0;
  padding: 0;
}

.blueBackground {
  background-color: #002E5D;
}

.homePageBackground {
  background-color: #284E83;
  height: 100vh;
}

.greyQRCodeContainer {
  background-color: #707070;
  height: 80vh;
  border-radius: 15%;
}

.blueQRCodeContainer {
  background-color: #284E83;
  height: 80vh;
  border-radius: 15%;
}

/* REGION IMAGE */
.imageHalfSize {
  width: 50%;
  height: 50%;
}

.imageFullSize {
  width: 100%;
  height: 100%;
}

.imageFullWidthOnly {
  width: 100%;
}

.imageHalfWidthOnly {
  width: 55%;
}

.imageAutoSize {
  width: 100%;
  height: auto;
}

.imageHalfAutoSize {
  width: 50%;
  height: auto;
}

/* REGION TEXT */
.boldBlueText {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #284E83;
  font-weight: bold !important;
}

.boldWhiteText {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: white;
  font-weight: bold !important;
  font-size: 4.5vh !important;
  text-align: center;
}
.whiteText{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: white;
  text-align: center;
  font-size: 3vh !important;

}

.textUppercase {
  text-transform: uppercase;
}

/* REGION GUIDA */

video::-webkit-media-controls-panel {
  display: none !important;
  opacity: 1 !important;
}
